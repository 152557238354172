<template>
  <div class="cy_aboutuser">
    <div class="cy_aboutuser_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" style="width:100%" />
    </div>
    <ul class="cy_aboutuser_head">
      <li
        v-for="(item, idx) in basicData.basicList"
        :key="idx"
        :class="[active == idx ? 'li_on' : '']"
        @click="active = idx"
      >
        {{ item.value }}
      </li>
    </ul>
    <div class="cy_aboutuser_nav" v-if="active == 0" style="margin-bottom: 0">
      <div class="nav_qyjj_list">
        <ul class="list_box">
          <li v-for="(item, idx) in qyjjData.qyjjList" :key="idx">
            <em
              :style="{ background: `url(${basicData.basicAll[0]}) no-repeat` }"
              >{{ item.key }}</em
            >
            <span>{{ item.value }}</span>
            <i>+</i>
          </li>
        </ul>
        <div class="list_text">{{ qyjjData.qyjjTxt }}</div>
      </div>
      <div class="nav_qyjj_text">
        {{ qyjjData.qyjjText }}
      </div>
      <ul class="nav_qyjj_list2">
        <li v-for="(item, idx) in qyjjData.qyjjList2" :key="idx">
          <div class="li_nav" v-if="idx == 0 || idx == 2">
            <div class="li_nav_pic">
              <img :src="item.key" alt="" style="width:100%;height:100%" />
            </div>
            <div class="li_nav_txt">
              <p class="text">{{ item.value }}</p>
            </div>
          </div>
          <div class="li_nav" v-if="idx == 1 || idx == 3">
            <div class="li_nav_txt li_nav_txt1">
              <p>{{ item.value }}</p>
            </div>
            <div class="li_nav_pic">
              <img :src="item.key" alt="" style="width:100%;height:100%" />
            </div>
            <div class="li_nav_txt li_nav_txt2" style="display:none">
              <p class="text">{{ item.value }}</p>
            </div>
          </div>
        </li>
      </ul>
      <div class="nav_qyjj_sqfs">
        <div class="pic">
          <img :src="qyjjData.qyjjImg" alt="" style="width: 100%;" />
        </div>
        <div class="nei">
          <div class="nei_img" v-if="Object.keys(basicData).length != 0">
            <img
              :src="basicData.basicAll[1]"
              alt=""
              style="width: 76px;height: 76px;"
            />
          </div>
          <p>{{ qyjjData.qyjjNr }}</p>
        </div>
      </div>
    </div>
    <div class="cy_aboutuser_nav2" v-if="active == 1">
      <h2 class="nav2_title">{{ pbwhData.ppwhTitle }}</h2>
      <ul class="nav2_list">
        <li v-for="(item, idx) in pbwhData.ppwhList" :key="idx">
          <div class="li_nav" v-if="idx == 0 || idx == 2">
            <div class="li_nav_pic">
              <img
                :src="pbwhData.ppwhImg[idx]"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <div class="li_nav_txt">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
          </div>
          <div class="li_nav" v-if="idx == 1 || idx == 3">
            <div class="li_nav_txt li_nav_txt1">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
            <div class="li_nav_pic">
              <img
                :src="pbwhData.ppwhImg[idx]"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <div class="li_nav_txt li_nav_txt2" style="display:none">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <h2 class="nav2_title">{{ pbwhData.ppwhTitle2 }}</h2>
      <ul class="nav2_list">
        <li v-for="(item, idx) in pbwhData.ppwhList2" :key="idx">
          <div class="li_nav" v-if="idx == 0 || idx == 2">
            <div class="li_nav_pic">
              <img
                :src="pbwhData.ppwhImg2[idx]"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <div class="li_nav_txt">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
          </div>
          <div class="li_nav" v-if="idx == 1 || idx == 3">
            <div class="li_nav_txt li_nav_txt1">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
            <div class="li_nav_pic">
              <img
                :src="pbwhData.ppwhImg2[idx]"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <div class="li_nav_txt li_nav_txt2" style="display:none">
              <h2
                class="nav_qyjj_title"
                :style="{
                  background: `url(${basicData.basicAll[1]}) no-repeat 6% center`
                }"
              >
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
              <div class="dian">
                <img :src="basicData.basicAll[2]" alt="" style="width:100%" />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cy_aboutuser_nav" v-if="active == 2">
      <Carousel :status="1" :list="fzlcList"></Carousel>
      <div class="nav_img" style="display: none">
        <img v-for="(item, idx) in fzlcImg" :key="idx" :src="item" alt="" style="width:100%" />
      </div>
    </div>
    <div class="cy_aboutuser_nav" v-if="active == 3">
      <ul class="nav_qyry_list">
        <li v-for="(item, idx) in pagedItems" :key="idx">
          <div class="li_img">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.value"
              :preview-src-list="qyryImg"
            >
            </el-image>
          </div>
          <p>{{ item.key }}</p>
        </li>
      </ul>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @prev-click="upChange"
          @next-click="nextChange"
          :current-page.sync="form.page"
          background
          :page-size="form.pageSize"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > form.pageSize"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
import Carousel from '@/common/Carousel/Carousel.vue'
export default {
  components: { Carousel },
  data () {
    return {
      active: 0,
      basicData: {},
      qyjjData: {},
      pbwhData: {},
      fzlcList: [
        {
          dataList: []
        },
        {
          dataList: []
        }
      ],
      fzlcImg: [],
      form: {
        page: 1,
        pageSize: 6
      },
      total: 0,
      qyryImg: [],
      qyryList: []
    }
  },
  watch: {},
  computed: {
    // 计算当前页的数据
    pagedItems () {
      const start = (this.form.page - 1) * this.form.pageSize
      const end = start + this.form.pageSize
      return this.qyryList.slice(start, end)
    },
    // 计算总页数
    totalPages () {
      return Math.ceil(this.qyryList.length / this.form.pageSize)
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.basicData = res.data.data.basic
          that.qyjjData = res.data.data.企业简介
          that.pbwhData = res.data.data.品牌文化
          that.fzlcList[0].dataList = res.data.data.发展历程.fzList
          that.fzlcList[1].dataList = res.data.data.发展历程.fzList2
          that.fzlcImg = res.data.data.发展历程.fzlcImg
          that.qyryList = res.data.data.企业荣誉.qyryList
          that.qyryImg = res.data.data.企业荣誉.qyryImg
          that.total = that.qyryList.length
        }
      })
    },
    upChange () {
      if (this.form.page < this.totalPages) this.form.page++
    },
    nextChange () {
      if (this.form.page > 1) this.form.page--
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.cy_aboutuser {
  width: 100%;
  height: 100%;
  color: #212529;
  .cy_aboutuser_head {
    width: 100%;
    border-bottom: 1px solid #d0d0d0;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 20px;
      font-size: 18px;
      padding: 30px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
    .li_zxsq {
      color: #191919;
      background-color: #fabe0d;
      border-radius: 20px;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      font-weight: 500;
      border-bottom: 3px;
    }
    .li_on {
      border-bottom: 3px solid #c8151d;
      font-weight: bold;
    }
  }
  .cy_aboutuser_nav {
    max-width: 1200px;
    margin: 80px auto;
    .nav_qyjj_list {
      background: #fff;
      padding: 70px 0 50px;
      border-bottom: 4px solid #f9bd0d;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      margin-top: 68px;
      text-align: center;
      ul {
        display: flex;
        li {
          width: calc(33.3333% - 20px);
          box-sizing: border-box;
          text-align: center;
          em {
            width: 87px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 16px;
            display: inline-block;
            margin-right: 10px;
          }
          span {
            font-size: 40px;
            color: #f9bd0d;
            font-weight: bold;
            line-height: 42px;
            display: inline-block;
            vertical-align: -5px;
          }
          i {
            font-size: 30px;
            color: #f9bd0d;
            margin-left: 8px;
            vertical-align: 2px;
          }
        }
      }
      .list_text {
        font-size: 14px;
        line-height: 34px;
        margin-top: 40px;
        text-align: right;
        padding-right: 90px;
      }
    }
    .nav_qyjj_text {
      padding: 50px 0 80px;
      text-indent: 2em;
      line-height: 2;
    }
    .nav_qyjj_sqfs {
      width: 178%;
      margin-top: 90px;
      overflow: hidden;
      margin-left: -40%;
      position: relative;
      .nei {
        width: 560px;
        margin-left: 25%;
        font-size: 14px;
        background-color: #fff;
        border-bottom: 10px solid #f9bd0d;
        margin-top: 150px;
        position: absolute;
        top: 0;
        .nei_img {
          margin: 30px 0 0 30px;
        }
        p {
          margin: 15px 55px 80px;
          line-height: 2.5;
        }
      }
    }
    .nav_qyjj_list2 {
      li {
        width: 100%;
        .li_nav {
          display: flex;
          .li_nav_pic {
            width: 50%;
            height: 350px;
          }
          .li_nav_txt {
            width: calc(50% - 100px);
            height: 350px;
            padding: 0 50px;
            text-indent: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #f2f2f2;
            p {
              line-height: 1.5;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .nav_yzfx_list {
      background-size: 100% auto !important;
      box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.07);
      border-radius: 2px;
      padding: 40px 12.04%;
      box-sizing: border-box;
      ul li {
        margin-bottom: 50px;
        .li_box {
          width: 170px;
          height: 170px;
          position: relative;
          margin: 0 auto;
          .open {
            width: 65px;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
          }
        }
        .li_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          margin: 20px auto;
          text-align: center;
        }
        .li_text {
          h2 {
            text-align: center;
            color: #222222;
            margin: 20px 0 30px;
          }
          p {
            line-height: 1.8;
            text-indent: 2em;
          }
          .li_text_close {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            text-align: center;
            text-decoration: underline;
            margin-top: 30px;
            cursor: pointer;
          }
        }
      }
    }
    .nav_qyry_list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 31.75%;
        margin: 40px 2.375% 0 0;
        text-align: center;
        .li_img {
          width: 100%;
          height: 280px;
          margin-bottom: 20px;
        }
      }
      li:nth-child(3n) {
        margin: 40px 0 0 0;
      }
      li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3) {
        margin-top: 0;
      }
    }
  }
  .cy_aboutuser_nav2 {
    .nav2_title {
      font-size: 32px;
      text-align: center;
      line-height: 34px;
      margin: 90px 0 80px 0;
    }
    .nav2_title:after {
      content: '';
      width: 30px;
      height: 4px;
      display: block;
      margin: 0 auto;
      background: #fabe0d;
      margin-top: 10px;
    }
    .nav2_list {
      padding-bottom: 100px;
      li {
        width: 100%;
        .li_nav {
          display: flex;
          .li_nav_pic {
            width: 50%;
          }
          .li_nav_txt {
            width: calc(50% - 100px);
            padding: 0 50px;
            text-indent: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #f2f2f2;
            p {
              line-height: 2;
              margin-top: 20px;
            }
            .dian {
              width: 200px;
              margin-top: 50px;
              text-indent: 0;
            }
          }
        }
      }
    }
  }
  .nav_qyjj_title {
    font-size: 30px;
    line-height: 3;
    font-weight: bold;
    text-indent: 0;
  }
}
</style>
